import { RuleActionValues, RuleTypes } from '@/utilities/rules';

export default function generatePayload({
  ruleType, companyId, name, conditions,
  eventData, priority = 1, isDefault,
  enabled = true,
}) {
  const basePayload = {
    companyId,
    ruleType,
    ruleDescription: name,
    enabled,
    ...isDefault && { isDefault: true },
    rule: {
      conditions: {
        all: conditions,
      },
      event: {
      },
    },
  };

  if (ruleType === RuleTypes.NON_RETURNABLE_ITEM) {
    basePayload.rule.event = {
      type: 'nonReturnableItem',
      params: {
        actionId: 'not_returnable',
        actionValue: {
          isNotReturnable: true,
        },
      },
    };
  }

  if (ruleType === RuleTypes.RETURN_WINDOWS) {
    basePayload.rule.event = {
      type: 'withinReturnWindow',
      params: {
        actionId: 'return_window',
        actionValue: {
          returnWindow: {
            days: eventData.days,
            from: eventData.from,
          },
        },
      },
    };
    basePayload.rule.priority = priority;
  }

  if (ruleType === RuleTypes.RETURN_ADDRESS) {
    basePayload.rule.event = {
      type: 'returnAddress',
      params: {
        actionId: 'return_address',
        actionValue: {
          locationId: eventData.locationId,
        },
      },
    };
    basePayload.rule.priority = priority;
  }

  if (ruleType === RuleTypes.ORDER_EXCEPTION) {
    basePayload.ruleDescription = name || 'Order exception rule';
    basePayload.rule.event = {
      type: 'exceptionAlwaysReturnableItem',
      params: {
        actionId: 'exception_always_returnable',
        actionValue: {
          alwaysReturnable: true,
        },
      },
    };
  }

  if (ruleType === RuleTypes.CARRIER_RULES) {
    basePayload.rule.event = {
      type: 'returnCarrier',
      params: {
        actionId: 'return_carrier',
        actionValue: {
          returnCarrier: {
            shouldUse: eventData.shouldUse === RuleActionValues.TRUE,
            carrier: eventData.carrier,
            serviceType: eventData.serviceType,
          },
        },
      },
    };
    basePayload.rule.priority = priority;
  }

  if (ruleType === RuleTypes.REFUND_TRIGGERS) {
    basePayload.rule.event = {
      type: 'refundTrigger',
      params: {
        actionId: 'refund_trigger',
        actionValue: {
          refundTrigger: {
            eventType: eventData.eventType,
          },
        },
      },
    };
    basePayload.rule.priority = priority;
  }

  if (ruleType === RuleTypes.REFUND_FEES) {
    basePayload.rule.event = {
      type: 'refundFee',
      params: {
        actionId: 'refund_fee',
        actionValue: {
          refundFee: {
            amount: eventData.amount,
            name: eventData.name,
          },
        },
      },
    };
    basePayload.rule.priority = priority;
  }

  return basePayload;
}
